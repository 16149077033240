import React,{ useState, useEffect, useRef} from 'react';
import {Link } from 'react-router-dom';

import imgPath from '../../images/trav_logo.png';
import './Header.css';




function Header1() {
  const [scrolled,setScrolled] = useState(false);
  const [notReg,setNotReg] = useState(false);
  const [mobMenu,setMobMenu] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(()=>{
    setMobMenu(mobMenu);   
  
    //setNotRegUser(false);
    window.addEventListener('scroll',handleScroll);
    document.addEventListener("mousedown", handleClick);   


    return(()=>{
      window.removeEventListener('scroll',handleScroll);
      document.removeEventListener("mousedown", handleClick);
    })
  },[mobMenu]);

  const handleScroll = ()=>{
    window.scrollY >0?setScrolled(true):setScrolled(false);
  }

  
   
  const handleClick = e => {   
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)){
      setMobMenu(false);
      setNotReg(false);
    }
  }

  const gonext = (idd)=>{
    setMobMenu(false);
    let element = document.querySelector("#"+idd);
    element.scrollIntoView({ behavior: 'smooth', block: 'end'});
  }

  const gonextHide = (cls,idd)=>{
    setMobMenu(false);
    if (document.querySelector('.'+cls+'.active') != null) {
      document.querySelector('.'+cls+'.active').classList.remove('active');
    }
    document.querySelector('#'+idd).classList.add('active');
  }

  const rmvActiveFun = (cls)=>{
    if (document.querySelector('.'+cls+'.active') != null) {
      document.querySelector('.'+cls+'.active').classList.remove('active');
    }
  }

  const mobileAction = ()=>{
    setMobMenu(true);
    mobMenu==true?setMobMenu(false):setMobMenu(true);
  }
  const notreg = ()=>{
    setNotReg(!notReg);
  }
  const ntreg = ()=> {
    setNotReg(false);
  }

  return (
    <header className={`${scrolled==true?'active':''} row_hb headerCntr`}>
      <div className="container">
        <div className="row_hb">
          <div className="logoBox">
            <Link to="/" onClick={()=>rmvActiveFun('mLink')}><img src={imgPath} alt="Travsie"/></Link>
          </div>
          <span className="mobileIcon" onClick={mobileAction}>
            <span></span>
            <span></span>
            <span></span>
          </span>
          <div ref={wrapperRef} className={`${mobMenu==true?'active':''} menuBox`}>
            <ul className="row_hb mar_b_0 text_align_r">
              <li><Link className="mLink" id="aboutLink" onClick={()=>gonextHide('mLink','aboutLink')} to="/about">About Us</Link></li>
              <li><Link className="mLink" id="benefitsLink" onClick={()=>gonextHide('mLink','benefitsLink')} to="/benefit">Benefits</Link></li>
              <li><Link className="mLink" id="agentsLink" onClick={()=>gonextHide('mLink','agentsLink')} to="/agent" >Agents</Link></li>
              <li><Link className="mLink" id="findagent" onClick={()=>gonextHide('mLink','findagent')} to="/premium_partner" style={{display:"none"}} >Find a Travel Agent</Link></li>
              <li><Link className="mLink" id="suppliersLink" onClick={()=>gonextHide('mLink','suppliersLink')} to="/supplier" >Suppliers</Link></li>
              <li><Link className="mLink" id="blogsLink" onClick={()=>gonextHide('mLink','blogsLink')} to="/blogs" >Blogs</Link></li>
              <li><a className="mLink"  href='https://www.travsie.com/gallery.php'>Partners</a></li>
              <li className='pos_relative'><a className="mLink" id="loginRegister"  onClick={notreg} >Register</a> 
              {notReg===true?<div ref={wrapperRef} className="row_hb submenu" id="submenu">
                    <Link to="/signup/agent" title="Sign Up for Agent" onClick={ntreg} className="btn pad_l_0 btn-sm  text_align_c mob_m_r_5 fnt_weight_500 border_rad_8 fnt_size_13 mob_min_width txt_color_3">Agent	</Link>
                    <Link to="/signup/supp"  title="Sign Up for Supplier" onClick={ntreg}  className="btn pad_l_0 btn-sm  text_align_c fnt_weight_500 border_rad_8 fnt_size_13 mob_min_width txt_color_3">Supplier </Link>
                    <Link to="/signup/hotelier"  title="Sign Up for Hotelier" onClick={ntreg} className="btn pad_l_0 btn-sm text_align_c pull-right fnt_weight_500 border_rad_8 fnt_size_13 mob_min_width txt_color_3">Hotelier </Link>
                </div>:null}
                 
              </li>
              <li className='hidden_sm'><a href='https://www.travsie.com/admin/login.php'>Agent Login</a></li>
            
              <li className='hidden_sm'><Link className="mLink" id="loginLink" onClick={()=>gonextHide('mLink','loginLink')} to="/login" >Supplier Login</Link></li>
              <li className='hidden_xs'><Link className="mLink" id="loginLink" onClick={()=>gonextHide('mLink','loginLink')} to="/login" >Login</Link></li>
             
              
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header1;
