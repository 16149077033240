import React,{useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import './Infringement.css';

function Infringement() {

  useEffect(()=>{
    getPagetitle();
  },[]);

  const getPagetitle = ()=>{
    document.title = 'Infringement Policy';
  }

  return (
    <section className="row_hb">
      <div className="row_hb">
        <div className="container">
          <div className="row_hb blogBox">
              <span className="lefttagicon"></span>
              <div className="row_hb">
                <div className="blogLeft font_fam_roboto">
                    <h2 className="privacyTitle">Infringement <span className="txt_color_3">Policy</span></h2>
                    <p><strong>Notice and Procedure for Making Claims of Right Infringements</strong></p>
                    <p>Travshop respect third parties’ Intellectual Property Rights.    Kindly notify Travshop if your rights are being infringed by submitting the Notice in a format as attached to this Policy. </p>

                    <p>Upon receipt of a Notice Form, Travshop may take certain actions, such as informing the users about the infringing content or removing information without any admission as to liability and without prejudice to any rights, remedies or defences, all of which are expressly reserved. </p>

                    <p>Furthermore, in submitting a Notice Form, you accord Travshop the right to use, reproduce, modify, adapt, publish, translate, create derivative works from, and display its content throughout the world in any media platform.  This includes forwarding the Notice Form to the parties involved in the provision of the allegedly infringing content. You agree to indemnify Travshop  for all claims brought by a third party against Travshop  arising out of or in connection with the submission of a Notice Form.</p>

          <p>Note on Third Party User Listings:  Please keep in mind that Third Party User listings are merely hosted on Travshop and are posted solely by the Third Party Users who may be contacted via their "User Information" page, accessible from any of their listings.</p>
                    
                    <p>Important Warning: giving false, misleading or inaccurate information in the Notice Form to Travshop may result in civil and/or criminal liability. You should contact a legal advisor should you have any questions.</p>
                    
                    <h3 id="collection">Notice Form:</h3>

                    <p>If you believe that your rights are being violated by an information on the Travshop , you may fill out and submit the Notice Form (below). This form needs to be signed can be sent via E-mail PDF to the Grievance Officer:</p>

                    <p>e-mail: infringement@travsie.com</p>
                    <p>Subject Line: Notice of Infringement</p>
                    <p>(We will accept a signed PDF via e-mail with the subject line "Notice of Infringement").</p>
                    <p>Please fill out the Notice form using the corresponding numbered paragraphs to frame your communication.</p>
                    
                    <h3 id="collection">Notice Form:</h3>
                    <div className="row_hb pad_l_20 pad_t_15">
                      <div className="row_hb mar_a_0 txt_color_8 fnt_size_16 fnt_weight_600">[INSERT FULL NAME AND TITLE] of [COMPANY NAME, IF APPLICABLE], state as follows:</div>
                      <div className="row_hb pad_t_5 pad_l_20">
                        <p><strong>Contact information</strong><br/><strong>(a)</strong> Your and / or your company's name, address, telephone number and contact email address.<br/><strong>(b)</strong>The contact email address and/or name which we will provide to Third Party Sellers (if relevant) so they may contact you to resolve any issues regarding your notification to us. If you are unable to  provide a separate contact email, you authorize Travshop  to use the contact information you provide in while registering with Travshop . </p>
                      </div>

                      <div className="row_hb pad_t_5 pad_l_20">
                        <p><strong>Listing's details and Allegation of Infringed Right:</strong><br/><strong>(a)</strong> The listing's URL and detailed description of the information that you claim is infringing your rights is located on Travshop  Platform; if regarding a Third Party User listing please also provide the name used to identify the User on the Platform. <br/><strong>(b)</strong>A description of your intellectual property right(s) that you claim has/have been infringed. Please provide the copy of Trademark Certificate / Copyright Registration Certificate / Patent Registration Certificate. [Please also provide as to how you have arrived in determining that third party Intellectual Property Rights have been infringed]</p>
                      </div>

                      <div className="row_hb pad_t_5 pad_l_20">
                        <p><strong>I Following statement be included </strong><br/><strong>(a)</strong> "I in my good faith belief that the portion of the listing(s) described above violate(s) the intellectual property rights owned by the intellectual property owner or its agent, nor is such use otherwise permissible under law." [Please provide a copy of authorization Certificate in favour of applicant]</p>
                      </div>

                      <div className="row_hb pad_t_5 pad_l_20">
                        <p>Include the following statement: "I represent that the information in this notification is true and correct and that I am the intellectual property owner or authorised to act on behalf of the intellectual property owner for the rights described above."</p>
                      </div>

                      <div className="row_hb pad_t_5 pad_l_20">
                        <p>Sign the Notice Form.</p>
                      </div>

                    </div>
                    
                </div>

                <div className="blogLeft privacy pos_relative">
                  &nbsp;
                </div>
              </div>
          </div>   
        </div>
      </div>
    </section>
  );
}

export default Infringement;